/**
 * @file It contains scripts for organisation-code view.
 */
import ThemisPinInput from "@/components/shared/pin-input"
import { mapActions, mapGetters } from "vuex"

export default {
  name      : "OrganisationCode",
  components: { ThemisPinInput },
  data() {
    return {
      organisationCode        : undefined,
      showOrganisationCodeHelp: false
    }
  },
  computed: {
    ...mapGetters({
      isOrganisationCodeVerified : "channels/isOrganisationCodeVerified",
      isVerifyingOrganisationCode: "channels/isVerifyingOrganisationCode"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapActions({
      verifyOrganisationCode: "channels/verifyOrganisationCode",
      notify                : "shared/notify"
    })
  },
  watch: {
    organisationCode: {
      immediate: false,
      handler  : function(value) {
        if (value) {
          this.verifyOrganisationCode({
            organisationCode: value,
            channelName     : this.$route.params.channel
          })
        }
      }
    },
    isOrganisationCodeVerified: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          this.$router.push({
            name  : "reports",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            }
          })
        } else if (newValue === false) {
          this.notify({
            type: "error",
            text: "13"
          })
        }
      }
    }
  }
}